import { Component } from 'react';
import { SWIGGY_URL, ZOMATO_URL } from '../utils/AppConstants';

export class Footer extends Component {
    render() {
        return <footer>
            <div className="frame black"/>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="footer_wp">
                            <i className="icon_pin_alt"/>
                            <h3>Location</h3>
                            <ul>
                                <li>Andheri</li>
                                <li>Santacruz</li>
                                <li>Parel</li>
                                <li>Mulund</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="footer_wp">
                            <i className="icon_tag_alt"/>
                            <h3>Order Now</h3>
                            <p>
                                <div><a href={ZOMATO_URL}>Zomato</a></div>
                                <div><a href={SWIGGY_URL}>Swiggy</a></div>
                                <div><a href="tel:919819555065">+91 98195-55065</a></div>
                            </p>

                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="footer_wp">
                            <i className="icon_clock_alt"/>
                            <h3>Opening Hours</h3>
                            <ul>
                                <li>Mon - Sat: 8am - 9pm</li>
                                <li>Sunday: 8am - 3pm</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* /row*/}
                <hr/>
                <div className="row">
                    <div className="col-sm-5">
                        <p className="copy">
                            © Thepla House by Tejal's Kitchen - All rights reserved
                        </p>
                    </div>
                    <div className="col-sm-7">
                        <div className="follow_us">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/TejalskitchenTH" target="_blank" rel="noreferrer">
                                        <img
                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                                            data-src="img/facebook_icon.svg"
                                            alt=""
                                            className="lazy"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/tejals_kitchen" target="_blank" rel="noreferrer">
                                        <img
                                            src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                                            data-src="img/instagram_icon.svg"
                                            alt=""
                                            className="lazy"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p className="text-center"/>
            </div>
        </footer>;
    }
}
