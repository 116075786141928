import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { scrollToId } from '../utils/InitUtils';

export class Banners extends Component {

    render() {
        return <Fragment>
            <ul id="banners_grid" className="clearfix">
                <li>
                    <Link to="/menu" className="img_container">
                        <img src="/img/banners_cat_placeholder.jpg" data-src="img/banner_1.jpg" alt=""
                             className="lazy"/>
                        <div className="short_info opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
                            <h3>Our Menu</h3>
                            <p>View our Full Menu</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <a onClick={()=>this.scrollToSpecialityMenu()} className="img_container">
                        <img src="/img/banners_cat_placeholder.jpg"
                             data-src="img/banner_2.jpg"
                             alt=""
                             className="lazy"/>
                        <div className="short_info opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
                            <h3>Our Specialities</h3>
                            <p>View our specialities</p>
                        </div>
                    </a>
                </li>
                <li>
                    <Link to="gallery" className="img_container">
                        <img src="/img/banners_cat_placeholder.jpg"
                             data-src="img/banner_3.jpg" alt="" className="lazy"/>
                        <div className="short_info opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
                            <h3>Food Gallery</h3>
                            <p>View the Gallery</p>
                        </div>
                    </Link>
                </li>
            </ul>
        </Fragment>;
    }

    scrollToSpecialityMenu() {
        scrollToId('speciality_menu')
    }
}
