import { Component, Fragment } from 'react';
import { MenuProduct } from './MenuProduct';
import { Link } from 'react-router-dom';

export class Menu extends Component {
    state = {
        products: [
            {
                name: 'Methi Thepla',
                description: '4 pieces with Chutney',
                thumbNailImage: 'img/menu_items/thepla_thumbnail.png',
                largeImage: 'img/menu_items/large/thepla.png'
            },
            {
                name: 'Dabeli',
                description: '2 Pieces - Contains Peanuts, Pomegranate, Potato & Onion',
                thumbNailImage: 'img/menu_items/dabeli_thumbnail.png',
                largeImage: 'img/menu_items/large/dabeli.png'
            },
            {
                name: 'Gujarati Mini Thali',
                description: 'Dal, Rice, 2 Sabzi, 3 Phulka Roti, 1 Gulab Jamun & Salad',
                thumbNailImage: 'img/menu_items/gujaratiminithali_thumbnail.png',
                largeImage: 'img/menu_items/large/gujaratiminithali.png'
            },
            {
                name: 'Punjabi Mini Thali',
                description: 'Dal Fry, Jeera Rice, 2 Sabzi, 2 Paratha, 1 Gulab Jamun & Salad',
                thumbNailImage: 'img/menu_items/punjabiminithali_thumbnail.png',
                largeImage: 'img/menu_items/large/punjabiminithali.png'
            },
            {
                name: 'Rajasthani Dal Bati Churma',
                description: 'Dal, 2 Bati, Churma, Garlic Chutney, Mirchi Kutta & Onion',
                thumbNailImage: 'img/menu_items/dalbatichurma_thumbnail.png',
                largeImage: 'img/menu_items/large/dalbatichurma.png'
            },
            {
                name: 'Sabudana Khichdi',
                description: 'Prepared with Sainda Namak (Rock Salt)',
                thumbNailImage: 'img/menu_items/sabudanakhichdi_thumbnail.png',
                largeImage: 'img/menu_items/large/sabudanakhichdi.png'
            },
            {
                name: 'Masala Chai',
                description: 'Serves 1 people',
                thumbNailImage: 'img/menu_items/chai_thumbnail.png',
                largeImage: 'img/menu_items/large/chai.png'
            },
            {
                name: 'Masala Chaas',
                description: 'Serves 1 person',
                thumbNailImage: 'img/menu_items/chaas_thumbnail.png',
                largeImage: 'img/menu_items/large/chaas.png'
            }
        ]
    };

    render() {
        return <Fragment>
            <div className="bg_gray" id="speciality_menu">
                <div className="container margin_120_100" data-cue="slideInUp">
                    <div className="main_title center mb-5">
                            <span>
                                <em/>
                            </span>
                        <h2>Our Specialities</h2>
                    </div>
                    {/* /main_title */}
                    <div className="banner lazy" data-bg="url(img/menu_items/collectivedishes.png)">
                        <div className="wrapper d-flex align-items-center justify-content-between opacity-mask"
                             data-opacity-mask="rgba(0, 0, 0, 0.55)">
                            <div>
                                <small>You can choose from</small>
                                <h3>250+ Items in our Menu</h3>
                                <p>Pure veg, Fasting & Jain Food available.</p>
                                <Link to="menu" className="btn_1">
                                    View Full Menu
                                </Link>
                            </div>
                        </div>
                        {/* /wrapper */}
                    </div>
                    {/* /banner */}
                    <div className="row magnific-gallery homepage add_bottom_25">
                        {this.state.products.map((product) => (
                            <div data-cue="slideInUp" className="col-lg-6">
                                <MenuProduct  key={product.name} product={product}/>
                            </div>
                        ))}
                    </div>
                    {/* /row */}
                    {/*<p className="text-center">*/}
                    {/*    <a href="#0" className="btn_1 outline" data-cue="zoomIn">*/}
                    {/*        Download Menu*/}
                    {/*    </a>*/}
                    {/*</p>*/}
                </div>
                {/* /container */}
            </div>
        </Fragment>;
    }
}
