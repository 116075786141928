import { Component } from 'react';
import { SWIGGY_URL, ZOMATO_URL } from '../utils/AppConstants';

export class OrderAppSection extends Component {

    render() {
        return <div className="pattern_2">
            <div className="container margin_120_100">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-2 text-center" data-cue="slideInUp"/>
                    <div className="col-lg-4 col-sm-8 text-center" data-cue="slideInUp">
                        <div className="main_title center"><span><em/></span>
                            <h2>Order our food from</h2>

                        </div>
                        <div className="row justify-content-center">
                            <div className="col-6 justify-content-center" data-cue="slideInUp">
                                <a href={ZOMATO_URL} target="_blank" rel="noreferrer">
                                    <img src="img/apps/zomato.png" alt="" className="zomato-icon"/>
                                </a>

                            </div>

                            <div className="col-6 justify-content-center" data-cue="slideInUp">
                                <a href={SWIGGY_URL} target="_blank" rel="noreferrer">
                                    <img src="img/apps/swiggy.png" alt="" className="swiggy-icon"/>
                                </a>

                            </div>
                        </div>
                        <div className="main_title text-center" data-cue="slideInUp">
                            <p>Or</p>
                            <h4>Call us at</h4>
                            <h2><a href="tel:919819555065">+91 98195-55065</a></h2>
                        </div>
                        <div className="main_title center"><span><em/></span></div>
                    </div>
                    <div className="col-lg-4 col-sm-2" data-cue="slideInUp"/>
                </div>
            </div>
        </div>;
    }
}
