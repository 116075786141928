import { Component } from 'react';
import { Link } from 'react-router-dom';

export class SpecialEvent extends Component {

    render() {
        return <div className="call_section lazy" data-bg="url(img/blog-1.jpg)">
            <div className="container clearfix">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6 text-center">
                        <div className="box_1" data-cue="slideInUp">
                            <h2>
                                Celebrate<span>a Special Event with us!</span>
                            </h2>
                            <p>
                                Join us in celebrating your special events with our exceptional services. We offer
                                catering options and cater to corporate needs with customized meals available on
                                pre-order. <br/>
                                Let us take care of your culinary requirements, ensuring a memorable and delightful
                                experience for you and your guests.
                            </p>
                            <Link to="/contactus" className="btn_1 mt-3">
                                Contact us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
