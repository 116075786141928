import React, { Component } from 'react';
import { applyOpacityMask, loadBackgroundImage, loadMagnificGallery, scrollToId } from '../utils/InitUtils';
import Layout from '../components/Layout';
import './ContactPage.css';
import { SWIGGY_URL, ZOMATO_URL } from '../utils/AppConstants';

class ContactPage extends Component {

    componentDidMount() {
        loadBackgroundImage();
        loadMagnificGallery();
        applyOpacityMask();
        window.$("html, body").animate({scrollTop: 0}, 500);
    }

    render() {
        const branches = [
            {
                id: 'andheri',
                name: 'Andheri',
                address: 'Shree Guppi ind. Estate, Saki vihar road, Gala Number 6, Chandivali, Mumbai, Maharashtra 400072',
                mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241428.03264867558!2d72.63646807304166!3d19.00592405789816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c7cb53bd0d51%3A0x37acf3c7a6f558e7!2sThepla%20House%20by%20Tejal\'s%20Kitchen%20-%20Andheri!5e0!3m2!1sen!2sin!4v1686905604010!5m2!1sen!2sin'
            },
            {
                id: 'santacruz',
                name: 'Santacruz',
                address: 'Chhabra Compound, Shivaji nagar Vakola, Vakola, Santacruz East, Mumbai, Maharashtra 400055',
                mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241428.03264867558!2d72.63646807304166!3d19.00592405789816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9bdf0490f11%3A0xb97dedb0056254d!2sThepla%20house%20by%20tejal\'s%20kitchen%20-%20Kalina%20Branch!5e0!3m2!1sen!2sin!4v1686905681160!5m2!1sen!2sin'
            },
            {
                id: 'parel',
                name: 'Lower Parel',
                address: '2R4P+6MQ, Krishna Nagar, Parel, Mumbai, Maharashtra 400012',
                mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15089.498042058858!2d72.82527491278707!3d19.003211963242933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf3a6b42a503%3A0xd4b70f5a98e593d2!2sThepla%20House%20By%20Tejal\'s%20Kitchen!5e0!3m2!1sen!2sin!4v1686905930752!5m2!1sen!2sin'
            },
            {
                id: 'mulund',
                name: 'Mulund',
                address: 'Gala No 77, Raja Industrial Estate, Mulund - Goregaon Link Rd, near D-Mart, Salpa Devi Pada, Mulund West, Mumbai, Maharashtra 400080',
                mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.6878744759524!2d72.9368448482383!3d19.165135303064538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b9ef9d0a0839%3A0x4c7d229a8972d02a!2sThepla%20House%20By%20Tejal\'s%20kitchen%20-%20Mulund!5e0!3m2!1sen!2sin!4v1686905984970!5m2!1sen!2sin'
            }
        ];
        return (
            <Layout>
                <div>
                    <div className="hero_single inner_pages background-image"
                         data-background="url(img/gallery/large/gallery_masonry_2.jpg)">
                        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-9 col-lg-10 col-md-8">
                                        <h1>Contact Us</h1>
                                        {/*<p>Per consequat adolescens ex cu nibh commune</p>*/}
                                    </div>
                                </div>
                                {/* /row */}
                            </div>
                        </div>
                        <div className="frame white"/>
                    </div>
                    {/* /hero_single */}
                    <div className="bg_gray">
                        <div className="container margin_60_40">
                            <div className="row justify-content-center">
                                <div className="col-lg-4">
                                    <div className="box_contacts">
                                        <h2>Place your Order On</h2>
                                        <a href={ZOMATO_URL} target="_blank" rel="noreferrer">
                                            <img src="img/apps/zomato.png" alt="" className="zomato-icon zomato-small-icon"/>
                                        </a>
                                        {/*<small>*/}
                                        {/*</small>*/}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="box_contacts">
                                        <h2>Place your Order On</h2>
                                        <a href={SWIGGY_URL} target="_blank" rel="noreferrer">
                                            <img src="img/apps/swiggy.png" alt="" className="swiggy-icon swiggy-small-icon"/>
                                        </a>
                                        {/*<small>*/}
                                        {/*</small>*/}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="box_contacts">
                                        <i className="icon_tag_alt"/>
                                        <h2>Personal/Party Orders</h2>
                                        <a href="tel:919819555065">+91 98195-55065</a>
                                        {/*<small>*/}
                                        {/*</small>*/}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="box_contacts">
                                        <i className="icon_pin_alt"/>
                                        <h2>Branches</h2>
                                        <div className="row flex-wrap">
                                            {branches.map((branch)=> (
                                                <div className="branch-name">
                                                    <a onClick={()=> scrollToId(branch.id)}>{branch.name}</a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="box_contacts">
                                        <i className="icon_clock_alt"/>
                                        <h2>Opening Hours</h2>
                                        <div>Mon - Sat: 8am - 9pm <span className="timings-separator">|</span> Sunday: 8am - 3pm</div>
                                    </div>
                                </div>

                            </div>
                            {/* /row */}
                        </div>
                        {/* /container */}
                    </div>
                    {/* /bg_gray */}
                    <div className="container margin_60_40">
                        <h5 className="mb_5 add_bottom_25">Our Branches</h5>
                        {branches.map((branch) => (
                            <div className="row add_bottom_25" id={branch.id}>
                                <div className="col-lg-4 col-md-6">
                                    <h6>{branch.name}</h6>
                                    <p>Address: {branch.address}</p>
                                </div>
                                <div className="col-lg-8 col-md-6 ">
                                    <iframe
                                        title={branch.name}
                                        src={branch.mapSrc}
                                        className="map_contact"
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />
                                </div>
                            </div>
                        ))}
                        {/* /row */}
                    </div>
                </div>
            </Layout>

        );
    }
}

export default ContactPage;
