
export function commonInit () {
    new window.LazyLoad({ elements_selector: ".lazy" });
    window.scrollCue.init();
}

export function loadBackgroundImage () {
    window.$(".background-image").each(function () {
        window.$(this).css("background-image", window.$(this).attr("data-background"));
    });
}

export function applyOpacityMask () {
    window.$(".opacity-mask").each(function () {
        window.$(this).css("background-color", window.$(this).attr("data-opacity-mask"));
    });
}

export function loadMagnificGallery () {
    window.$(".magnific-gallery").each(function () {
        window.$(this).magnificPopup({
            delegate: "a",
            type: "image",
            preloader: true,
            gallery: { enabled: true },
            removalDelay: 500,
            callbacks: {
                beforeOpen: function () {
                    this.st.image.markup = this.st.image.markup.replace(
                        "mfp-figure",
                        "mfp-figure mfp-with-anim"
                    );
                    this.st.mainClass = this.st.el.attr("data-effect");
                },
            },
            closeOnContentClick: true,
            midClick: true,
        });
    });
}

export function stickyElements () {
    window.$(window).on("scroll", function () {
        if (window.$(this).scrollTop() > 1) {
            window.$(".element_to_stick").addClass("sticky");
        } else {
            window.$(".element_to_stick").removeClass("sticky");
        }
    });
}

export function scrollToId(id) {
    window.$("html, body").animate({scrollTop: window.$(`#${id}`).offset().top - 75}, 2000);
}
const executeCode = () => {
    // Your code here...

    window.$(window).scroll(function () {
        if (window.$(window).scrollTop() >= 800) {
            window.$("#toTop").addClass("visible");
        } else {
            window.$("#toTop").removeClass("visible");
        }
    });

    window.$("#toTop").on("click", function () {
        window.$("html, body").animate({ scrollTop: 0 }, 500);
        return false;
    });

    window.$(".menu-gallery").each(function () {
        window.$(this).magnificPopup({
            delegate: "figure a",
            type: "image",
            preloader: true,
            gallery: { enabled: true },
            removalDelay: 500,
            callbacks: {
                beforeOpen: function () {
                    this.st.image.markup = this.st.image.markup.replace(
                        "mfp-figure",
                        "mfp-figure mfp-with-anim"
                    );
                    this.st.mainClass = this.st.el.attr("data-effect");
                },
            },
            closeOnContentClick: true,
            midClick: true,
        });
    });

    window.$("#staff").owlCarousel({
        center: true,
        items: 2,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        margin: 10,
        responsive: { 0: { items: 1 }, 600: { items: 2 }, 1000: { items: 4 } }
    });

    window.$(".carousel_testimonials").owlCarousel({
        items: 1,
        loop: true,
        autoplay: false,
        animateIn: "flipInX",
        margin: 40,
        stagePadding: 30,
        smartSpeed: 300,
        responsiveClass: true,
        responsive: { 600: { items: 1 }, 1000: { items: 1, nav: false } }
    });

    window.$(".btn_play, .video").magnificPopup({
        type: "iframe",
        closeMarkup:
            '<button title="%title%" type="button" class="mfp-close" style="font-size:24px; margin-right:-10px;">&#215;</button>'
    });

    window.$('a[href^="#"].btn_scroll').on("click", function (e) {
        e.preventDefault();
        var t = this.hash,
            e = window.$(t);
        window.$("html, body")
            .stop()
            .animate({ scrollTop: e.offset().top }, 800, "swing", function () {
                window.location.hash = t;
            });
    });
};
