import React, { Fragment } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';

class Layout extends React.Component {
    render() {
        return (
            <Fragment>
                <Header/>
                <main>{this.props.children}</main>
                <Footer/>
            </Fragment>
        );
    }
}

export default Layout;
