import { Component } from 'react';
import { MenuProduct } from '../components/MenuProduct';
import { applyOpacityMask, commonInit, loadBackgroundImage, loadMagnificGallery } from '../utils/InitUtils';

export class MenuTabContent extends Component {
    componentDidMount() {
        commonInit();
        loadBackgroundImage();
        loadMagnificGallery();
        applyOpacityMask();
    }

    render() {
        return <div id={`pane-` + this.props.tab.name}
                    className={`card tab-pane fade show ${this.props.tab.active ? "active" : ""}`}
                    role="tabpanel"
                    aria-labelledby={`tab-` + this.props.tab.name}>
            <div className="card-header" role="tab" id={`heading-` + this.props.tab.name}>
                <h5>
                    <a className="collapsed"
                       data-bs-toggle="collapse"
                       href={`#collapse-` + this.props.tab.name}
                       aria-expanded={this.props.tab.active}
                       aria-controls={`collapse-` + this.props.tab.name}>
                        {this.props.tab.title}
                    </a>
                </h5>
            </div>
            <div id={`collapse-` + this.props.tab.name} className="collapse" role="tabpanel"
                 aria-labelledby={`heading-` + this.props.tab.name}>
                <div className="card-body">
                    <div className="row magnific-gallery add_top_30">
                        {this.props.tab.products.map((product) => (
                            <div className="col-lg-6">
                                <MenuProduct key={product.name} product={product}/>
                            </div>
                        ))}
                    </div>
                    {/* /row */}
                </div>
                {/* /card-body */}
            </div>
        </div>;
    }
}
