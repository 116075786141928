import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { applyOpacityMask, commonInit, loadBackgroundImage, loadMagnificGallery } from '../utils/InitUtils';

export class MenuProduct extends Component {

    componentDidMount() {
        commonInit();
        loadBackgroundImage();
        loadMagnificGallery();
        applyOpacityMask();
    }

    render() {
        return <div className="menu_item">
                <figure>
                    <a href={this.props.product.largeImage} title={this.props.product.name}
                       data-effect="mfp-zoom-in">
                        <img src="img/menu_items/menu_items_placeholder.png"
                             data-src={this.props.product.thumbNailImage} className="lazy" alt=""/>
                    </a>
                </figure>
                <div className="menu_title">
                    <h3>{this.props.product.name}</h3>
                    {this.props.product.price &&
                        <em> ₹ {this.props.product.price}</em>
                    }
                </div>
                <p>{this.props.product.description}</p>
        </div>;
    }
}

MenuProduct.propTypes = {product: PropTypes.any};
