import { Component } from 'react';
import { applyOpacityMask, commonInit, loadBackgroundImage } from '../utils/InitUtils';

export class AboutUs extends Component {
    componentDidMount () {
        commonInit();
        loadBackgroundImage();
        applyOpacityMask();
    }
    render() {
        return <div className="pattern_2">
            <div className="container margin_120_100 home_intro">
                <div className="row justify-content-center d-flex align-items-center">
                    <div className="col-lg-5 text-lg-center d-none d-lg-block" data-cue="slideInUp">
                        <figure>
                            <img src="/img/home_1.jpg"
                                 alt=""
                                 className="img-fluid lazy"
                                 width={354}
                                 height={440}
                            />
                            <a href="https://www.youtube.com/watch?v=MO7Hi_kBBBg" className="btn_play" data-cue="zoomIn"
                               data-delay={500}>
                                        <span className="pulse_bt">
                                            <i className="arrow_triangle-right"/>
                                        </span>
                            </a>
                        </figure>
                    </div>
                    <div className="col-lg-5 pt-lg-4" data-cue="slideInUp" data-delay={500}>
                        <div className="main_title">
                                    <span>
                                        <em/>
                                    </span>
                            <h2>About Us</h2>
                            {/*<p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>*/}
                        </div>
                        <p>
                            Our restaurant originated from a deep-rooted passion for home-cooked food. Starting from a
                            humble home kitchen, we have now grown and established ourselves at four prominent locations
                            in Mumbai.
                        </p>
                        <p>
                            At the heart of our success lies our founder and CEO, Tejal Shah, whose
                            dedication and love for cooking inspired her to share her recipes with the world. What once
                            began as meals prepared for her family and friends, has now become a way to bring this
                            delightful cuisine to the masses
                        </p>
                        <p>
                            <img
                                src="/img/signature.png"
                                alt=""
                                className="mt-3"
                                width={140}
                                height={50}
                            />
                        </p>
                    </div>
                </div>
                {/*/row */}
            </div>
            {/*/container */}
        </div>;
    }
}
