import React  from 'react';
import './TextRotator.css';

class TextRotator extends React.Component {
    componentDidMount() {
        window.typed = new window.Typed('.element', {
            strings: ["Home Made Food", "No Added Preservatives", "Junk The Junk Food"],
            startDelay: 10,
            loop: true,
            backDelay: 2000,
            typeSpeed: 100
        });
    }

    render() {
        return (
            <div className="hero_single version_2 background-image cover" data-background="url(img/slides/slide_home_1.jpg)">
                <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-9 col-lg-10 col-md-8">
                                <img
                                    src="/img/thepla_house.svg"
                                    alt=""
                                    className="text_rotator_logo"
                                    width="50%"
                                    height="50%"
                                />
                                <p className="element_text_container">
                                    <span className="element" />
                                </p>
                                <a href="#reserve" className="btn_1 btn_scroll add_top_30">
                                    Order Now
                                </a>
                            </div>
                        </div>
                        {/* /row */}
                    </div>
                </div>
                <div className="frame white" />
            </div>

        );
    }
}

export default TextRotator;
