import React, { Component, Fragment } from 'react';
import { Banners } from '../components/Banners';
import { AboutUs } from '../components/AboutUs';
import { Menu } from '../components/Menu';
import { SpecialEvent } from '../components/SpecialEvent';
import { OrderAppSection } from '../components/OrderAppSection';
import Layout from '../components/Layout';
import TextRotator from '../components/TextRotator';
import { applyOpacityMask, loadBackgroundImage, loadMagnificGallery } from '../utils/InitUtils';

class HomePage extends Component {

    componentDidMount() {
        loadBackgroundImage();
        loadMagnificGallery();
        applyOpacityMask();
        window.$("html, body").animate({ scrollTop: 0 }, 500);
    }

    render() {
        return (
            <Fragment>
                <Layout>
                    <TextRotator/>
                    <Banners/>
                    <AboutUs/>
                    <Menu/>
                    <SpecialEvent/>
                    <OrderAppSection/>
                </Layout>
            </Fragment>

        );
    }
}

export default HomePage;
