import './App.css';
import { Component, Fragment } from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import MenuPage from './Pages/MenuPage';
import ContactPage from './Pages/ContactPage';

class App extends Component {

    render() {
        const router = createHashRouter([
            {
                path: "/",
                element: <HomePage/>,
            },
            {
                path: "/menu",
                element: <MenuPage/>
            },
            {
                path: "/contactus",
                element: <ContactPage/>
            }
        ], );

        return (
            <Fragment>
                {/*<div id="preloader">*/}
                {/*    <div data-loader="circle-side"/>*/}
                {/*</div>*/}
                <RouterProvider router={router}/>
            </Fragment>
        );
    }
}

export default App;
