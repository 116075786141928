import { Component, Fragment } from 'react';
import Layout from '../components/Layout';
import { MenuTabContent } from './MenuTabContent';
import { applyOpacityMask, commonInit, loadBackgroundImage, loadMagnificGallery } from '../utils/InitUtils';
import './MenuPage.css';

const menuData = require('../data/menu.json');

export class MenuPage extends Component {

    state = {
        tabs: []
    };

    componentDidMount() {
        this.initData();
        commonInit();
        loadBackgroundImage();
        loadMagnificGallery();
        applyOpacityMask();

        window.$(".tabs_menu_ul_wrapper").on('scroll', function () {
            const val = window.$(this).scrollLeft();
            val + window.$(this).innerWidth() >= window.$(this)[0].scrollWidth ? window.$(".nav-next").hide() : window.$(".nav-next").show();
            val === 0 ? window.$(".nav-prev").hide() : window.$(".nav-prev").show();
        });

        window.$(".nav-next").on("click", function(){


        });
        window.$(".nav-prev").on("click", function(){

        });

        window.$("html, body").animate({scrollTop: 0}, 500);
    }

    scrollLeft() {
        window.$(".tabs_menu_ul_wrapper").animate( { scrollLeft: '-=460' }, 200);
    }

    scrollRight() {

        window.$(".tabs_menu_ul_wrapper").animate( { scrollLeft: '+=460' }, 200);
    }

    initData() {
        for (const [key, value] of Object.entries(menuData)) {
            let new_id = key.replaceAll(' ', '');
            new_id = new_id.replaceAll('&', '');
            const tabExists = this.state.tabs.some(element => element['id'] === new_id);
            if (!tabExists) {
                this.state.tabs.push({id: new_id, text: key, products: value});
            }
        }
        this.setState(this.state);
    }

    render() {
        return (
            <Fragment>
                <Layout>
                    <div className="hero_single inner_pages background-image"
                         data-background="url(img/flex_slides/slide_1.jpg)">
                        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-9 col-lg-10 col-md-8">
                                        <h1>Our Menu</h1>
                                        <p>Junk the Junk Food</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="frame white"/>
                    </div>
                    <div className="pattern_2">
                        <div className="container margin_60_40">
                            <div className="tabs_menu add_bottom_25">
                                <div className="tabs_menu_ul_wrapper">
                                        <button className="nav-prev arrow" style={{display: 'none', backgroundImage: `url(${'/img/chevron-left.png'})`}} onClick={()=>this.scrollLeft()}/>
                                        <ul className="nav-tabs scroll" role="tablist">
                                            {this.state.tabs.map((tab, index) => (
                                                <li key={tab.id} className="nav-item">
                                                    <a id={`tab-` + tab.id} href={`#pane-` + tab.id}
                                                       className={`nav-link ${index === 0 ? "active" : ""}`}
                                                       data-bs-toggle="tab"
                                                       role="tab">
                                                        {tab.text}
                                                    </a>
                                                </li>))}
                                        </ul>
                                        <button className="nav-next arrow" style={{backgroundImage: `url(${'/img/chevron-right.png'})`}} onClick={()=>this.scrollRight()}/>

                                </div>

                                <div className="tab-content add_bottom_25" role="tablist">
                                    {this.state.tabs.map((tab, index) => (
                                            <MenuTabContent key={tab.id} tab={{
                                                name: tab.id,
                                                title: tab.text,
                                                products: tab.products,
                                                active: index === 0
                                            }}/>
                                        )
                                    )}
                                </div>
                            </div>
                            {/*<p className="text-center">*/}
                            {/*    <a href="#0" className="btn_1 outline">*/}
                            {/*        Download Menu*/}
                            {/*    </a>*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </Layout>
            </Fragment>
        );
    }
}

export default MenuPage;
