import { Component, Fragment } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

export class Header extends Component {
    componentDidMount() {

        window.$("a.show-submenu").on("click", function () {
            window.$(this).next().toggleClass("show_normal");
        });

        window.$(window).bind("load resize", function () {
            if (window.$(window).width() <= 768) {
                window.$("a.cart_bt").removeAttr("data-bs-toggle", "dropdown");
            } else {
                window.$("a.cart_bt").attr("data-bs-toggle", "dropdown");
            }
        });
    }

    openMobileMenu () {
        window.$(".main-menu").toggleClass("show");
        window.$(".layer").toggleClass("layer-is-visible");
    }

    render() {
        return <Fragment>
            <header className="header clearfix element_to_stick">
                <div className="layer"/>
                {/* Opacity Mask Menu Mobile */}
                <div className="container-fluid">
                    <div id="logo">
                        <Link to="/">
                            <img
                                src="/img/thepla_house.svg"
                                alt=""
                                className="logo_normal"
                                width={90}
                                height={90}
                            />
                            <img
                                src="/img/thepla_house.png"
                                alt=""
                                className="logo_sticky"
                                width={90}
                                height={90}
                            />
                        </Link>
                    </div>
                    <a className="open_close" onClick={() => this.openMobileMenu()}>
                        <i className="icon_menu"></i><span>Menu</span>
                    </a>
                    <nav className="main-menu">
                        <div id="header_menu">
                            <a  onClick={() => this.openMobileMenu()} className="open_close">
                                <i className="icon_close"/>
                                <span>Menu</span>
                            </a>
                            <Link to="/">
                                <img src="/img/thepla_house.svg" alt="" width={90} height={90}/>
                            </Link>
                        </div>
                        <ul>
                            <li><Link to="/menu" className="btn_top">Menu</Link></li>
                            <li><Link to="/gallery" className="btn_top">Gallery</Link></li>
                            <li><Link to="/contactus" className="btn_top">Contact Us</Link></li>
                            {/*<li className="submenu">*/}
                            {/*    <a href="#0" className="show-submenu">*/}
                            {/*        Other Pages*/}
                            {/*    </a>*/}
                            {/*    <ul>*/}
                            {/*        <li>*/}
                            {/*            <a href="about.html">About</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="blog.html">Blog</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="gallery.html">Gallery</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="gallery-2.html">Gallery Masonry</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="modal-advertise.html">Modal Advertise</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="modal-newsletter.html">Modal Newsletter</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="404.html">404 Error page</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="coming-soon.html" target="_blank">*/}
                            {/*                Coming Soon*/}
                            {/*            </a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="leave-review.html">Leave a review</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="contacts.html">Contacts</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="icon-pack-1.html">Icon Pack 1</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="icon-pack-2.html">Icon Pack 2</a>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                            {/*<li className="submenu">*/}
                            {/*    <a href="#0" className="show-submenu">*/}
                            {/*        Shop*/}
                            {/*    </a>*/}
                            {/*    <ul>*/}
                            {/*        <li>*/}
                            {/*            <a href="shop-1.html">Shop Grid</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="shop-2.html">Shop Rows</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="shop-single.html">Product Single</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="shop-cart.html">Cart Page</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="shop-checkout.html">Checkout</a>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <a href="https://1.envato.market/P0b7k6" target="_parent">*/}
                            {/*        Buy this template*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </nav>
                </div>
            </header>
            {/* /header */}
        </Fragment>;
    }
}
